// @flow

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import axios, { type Axios } from 'axios'
import ClientOAuth2 from 'client-oauth2'
import url from 'url'
import PageNotFoundErrorPage from './PageNotFoundErrorPage'
import { fetchProgram } from './ProgramPreview/Api'
import type { ProgramPayload } from './ProgramPreview/Api'
import LoadingScreen from './Guidelines/LoadingScreen'
import CustomerAreaBridge from './CustomerAreaBridge'
import CustomerContext from './CustomerAreaBridge/Context'

const ProgramPreview = React.lazy(() => import('./ProgramPreview'))
const CustomerArea = React.lazy(() => import('./CustomerArea'))
const Configurateur3DApplication = React.lazy(() =>
  import('./FlatConfigurator/Configurateur3DApplication'),
)
const ConfigurationScreenshotCapture = React.lazy(() =>
  import('./ConfigurationScreenshotCapture'),
)

type Props = {|
  apiEndpoint: string,
  oauthClientId: string,
  oauthClientSecret: string,
|}

type State = {|
  httpClient: Axios,
  authClient: ClientOAuth2,
  program: ?ProgramPayload,
|}

const getCurrentHostname = () => {
  const hostname = window.location.hostname
  const port = window.location.port

  if (process.env.NODE_ENV !== 'production' && hostname === 'localhost') {
    return `${hostname}:${port}`
  }

  return hostname
}

export default class MainApplication extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const httpClient = axios.create({ baseURL: props.apiEndpoint })

    // The browser version of the `url` module implement the deprecated version of the `url` API
    const accessTokenUri = url.format({
      ...url.parse(props.apiEndpoint || '/'), // eslint-disable-line node/no-deprecated-api
      ...{ pathname: '/oauth/v2/token' },
    })

    const authClient = new ClientOAuth2({
      clientId: props.oauthClientId,
      clientSecret: props.oauthClientSecret,
      accessTokenUri,
      scopes: [],
    })

    this.state = {
      httpClient,
      authClient,
      program: null,
    }
  }

  async componentDidMount() {
    const { httpClient } = this.state

    const programHostname = getCurrentHostname()
    const program = await fetchProgram(httpClient, programHostname)

    document.title = program.label

    this.setState({ program })
  }

  render() {
    const { httpClient, authClient, program } = this.state

    if (!program) {
      return <LoadingScreen isVisible />
    }

    return (
      <CustomerAreaBridge
        httpClient={httpClient}
        authClient={authClient}
        programId={program.programId}
      >
        <React.Suspense fallback={<LoadingScreen isVisible />}>
          <Switch>
            {/* Redirect to the homepage. Only for development environment. The production redirection is configured in the now.json file */}
            <Route exact path="/" render={() => <Redirect to="/program" />} />

            <Route
              exact
              path="/program"
              render={() => (
                <CustomerContext.Consumer>
                  {customerContextApi => (
                    <ProgramPreview
                      httpClient={httpClient}
                      customerContextApi={customerContextApi}
                      program={program}
                    />
                  )}
                </CustomerContext.Consumer>
              )}
            />

            <Route
              exact
              path="/lot/:lotId"
              render={props => (
                <CustomerContext.Consumer>
                  {customerContextApi => (
                    <Configurateur3DApplication
                      httpClient={httpClient}
                      customerContextApi={customerContextApi}
                      programId={program.programId}
                      pack={program.pack}
                      contact={program.links.contact}
                      lotId={props.match.params.lotId}
                    />
                  )}
                </CustomerContext.Consumer>
              )}
            />

            <Route
              path="/customer"
              render={() => (
                <CustomerContext.Consumer>
                  {customerContextApi => (
                    <CustomerArea
                      httpClient={httpClient}
                      customerContextApi={customerContextApi}
                    />
                  )}
                </CustomerContext.Consumer>
              )}
            />

            <Route
              exact
              path="/screenshot/:lotId"
              render={props => (
                <ConfigurationScreenshotCapture
                  httpClient={httpClient}
                  programId={program.programId}
                  lotId={props.match.params.lotId}
                />
              )}
            />

            <Route render={() => <PageNotFoundErrorPage />} />
          </Switch>
        </React.Suspense>
      </CustomerAreaBridge>
    )
  }
}
